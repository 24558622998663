import React from 'react'
import ContractorSidebar from '../../components/contractorSidebar'
import ContractorProfile from '../../components/contractor/ContractorProfile'
import SEO from '../../components/seo/seo'
import DisastrousErrorHandler from '../../components/ui/DisastrousErrorHandler';

const ContractorProfileScreen = () => (
  <DisastrousErrorHandler homeLink="/contractor/jobs">
		<ContractorSidebar>
			<SEO title="Profile" keywords={['3d', 'construction']} />
			<ContractorProfile />
		</ContractorSidebar>
  </DisastrousErrorHandler>
)

export default ContractorProfileScreen;
