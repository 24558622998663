import React from 'react'
import styled from 'styled-components'
import { useMutation } from '@apollo/react-hooks'

import { useGetContractorDetailsQuery } from '../../../apollo/generated'
import { SET_CONTRACTOR_AVATAR, SET_CONTRACTOR_FIELD } from '../../../apollo/mutations'
import FallbackAvatar from '../../ui/FallbackAvatar'
import EditableText from '../../ui/EditableText'
import UploadOverlay from '../../ui/UploadOverlay'
import { FormInput } from '../../sharedStyles'
import type { ContractorFragmentFragment as Contractor } from '../../../apollo/generated'
import Loading from '../../ui/Loading';

interface Props {
  readOnly?: boolean
  providedContractor?: Contractor
  compact?: boolean
  dark?: boolean
}

const createField = (value: any, label: string, onChange: (new_value: string) => void, opts?: { disabled?: boolean }) => (
  (opts && opts.disabled && !value) ? null : <EditableText
    value={value || ''}
    title={label}
    onChange={onChange}
    {...(opts || {})}
  >
    <ClickableField
      InputLabelProps={{ shrink: !!value }}
      label={label}
      value={value ? value : undefined}
      variant={(opts && opts.disabled) ? 'standard' : 'outlined'}
    />
  </EditableText>
)

const UpdateBasicInfo = ({ dark, readOnly, providedContractor, compact }: Props) => {
  let contractor = providedContractor
  let loading = false
  let refetch = () => { }

  // Hooks cannot be called conditionally. Try using skip.
  const r = useGetContractorDetailsQuery({ skip: !!providedContractor });
  const [SetContractorAvatar] = useMutation(SET_CONTRACTOR_AVATAR)
  const [SetContractorField] = useMutation(SET_CONTRACTOR_FIELD)

  if (!providedContractor) {
    refetch = r.refetch
    loading = r.loading
    contractor = r.data?.contractor
  }

  const SetField = async (fieldname: string, value: any) => {
    await SetContractorField({ variables: { [fieldname]: value } })
    refetch()
  }
  if (loading) {
    return (
      <Outer>
        <Loading dark relative />
      </Outer>
    );
  } else if (!contractor) {
    return <div>Could not fetch contractor</div>
  }

  return (
    <Outer dark={dark} compact={compact}>
      <TopRow>
        <UploadOverlay disabled={readOnly} onUpload={([url]) => SetContractorAvatar({ variables: { url } })}>
          <FallbackAvatar
            src={contractor.avatar && contractor.avatar.url}
            name={contractor.companyName}
            size={compact ? 68 : 182}
          />
        </UploadOverlay>
        <FormItems readOnly={readOnly} compact={compact}>
          {createField(contractor.companyName, 'Company Name', companyName => SetField('companyName', companyName), { disabled: readOnly })}
          {createField(contractor.companyAdminName, 'Company Rep Name', companyAdminName => SetField('companyAdminName', companyAdminName), { disabled: readOnly, fullWidth: true })}
          {createField(contractor.companyPosition, 'Company Position', companyPosition => SetField('companyPosition', companyPosition), { disabled: readOnly, fullWidth: true })}
          {createField(contractor.companyPhone, 'Company Phone', companyPhone => SetField('companyPhone', companyPhone), { disabled: readOnly })}
          {createField(contractor.companyAddress, 'Company Address', companyAddress => SetField('companyAddress', companyAddress), { disabled: readOnly })}
          <EmailField
            label="Company Email"
            value={contractor.user && contractor.user.email}
            variant={readOnly ? 'standard' : 'outlined'}
          />
          {createField(contractor.primaryWebsite, 'Primary Website Link', primaryWebsite => SetField('primaryWebsite', primaryWebsite), { disabled: readOnly })}
          {createField(contractor.secondaryWebsite, 'Secondary Website Link', secondaryWebsite => SetField('secondaryWebsite', secondaryWebsite), { disabled: readOnly })}
        </FormItems>
      </TopRow>
      {(readOnly && !contractor.companyBio) ? null : <EditableText
        disabled={readOnly}
        value={contractor.companyBio || ''}
        title="Company Bio"
        onChange={(companyBio) => SetField('companyBio', companyBio)}
        multiline
      >
        <ClickableField
          label="Company Bio"
          placeholder="Write a description of your company"
          variant={readOnly ? 'standard' : 'outlined'}
          value={contractor.companyBio ? contractor.companyBio : undefined}
          multiline
          rows={5}
        />
      </EditableText>}
    </Outer>
  )
}

export default UpdateBasicInfo

const Outer = styled.div<{ compact?: boolean, dark?: boolean }>`
  ${({ compact }) => {
    if (compact) {
      return `
        padding: 8px;
      `
    } else {
      return `
        box-shadow: 0 2px 4px rgba(0,0,0,.4);
        padding: 34px;
        border-radius: 4px;
        background: white;
      `
    }
  }}
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;

  ${({ dark }) => !dark ? '' : `
    * {
      color: white;
      border-color: white;
    }
    *:before {
      border-color: rgba(255,255,255,.42);
    }
  `}
`

const TopRow = styled.div`
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 24px;
`

const ClickableField = styled(FormInput).attrs(props => ({
  onChange: () => { },
  margin: props.margin || 'normal',
  variant: props.variant || 'outlined'
}))`
  pointer-events: none;
`

const EmailField = styled(ClickableField)`
  label {
    margin-left: 12px;
  }
`

const FormItems = styled.div<{ readOnly?: boolean, compact?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  position: relative;
  margin-left: 32px;
  > * {
    width: 50%;
    padding: 0 12px;
    min-width: 280px;
    overflow: visible;
  }

  * {
    ${({ compact }) => compact ? 'font-size: 14px;' : ''}
  }

  ${({ readOnly }) => readOnly ? `input:after, input:before {
    display: none;
  }` : ''}
`
