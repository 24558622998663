import React from 'react'

import Trades from '../signup/contractor/tradeList'
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_TRADES } from '../../apollo/queries';
import { UPDATE_CONTRACTOR_TRADES } from '../../apollo/mutations';
import { useGetContractorDetails } from '../../apollo/queries'
import { Trade } from '../../apollo/types';
import Swal from 'sweetalert2';

const UpdateJobTypes = () => {
  const { data: tradesData, loading: loadingTrades } = useQuery(GET_TRADES);
  const { data: contractorData, loading: loadingContractor } = useGetContractorDetails();
  const [updateContractorTrades] = useMutation<{}, { trades: string[] }>(UPDATE_CONTRACTOR_TRADES);
  const allTrades = loadingTrades ? [] : tradesData.trades;
  const selectedTrades = loadingContractor ? [] : contractorData.contractor.trades;

  const toggleTrade = (trade, enabled: boolean) => {
    const trades = selectedTrades || [];

    let newTrades: Trade[];
    if (enabled && !trades.find(t => t.id === trade.id)) {
      newTrades = [...trades, trade];
    } else if (!enabled && trades.find(t => t.id === trade.id)) {
      newTrades = trades.filter(t => t.id !== trade.id);
    }

    if (newTrades) {
      updateContractorTrades({
        variables: { trades: newTrades.map(t => t.id) }
      }).then(() => {
        Swal.fire({
          title: 'Success',
          text: 'Trades Updated Successfully!',
          type: 'success'
        })
      })
    }
  }

  return (
    <>
      <Trades hideCost noContainer trades={allTrades} selectedTrades={selectedTrades} toggleTrade={toggleTrade} />
    </>
  )
}

export default UpdateJobTypes
