import React from 'react'
import { Typography, Chip } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

import styled from 'styled-components'
import { useMutation } from '@apollo/react-hooks'

import { TransparentCard } from '../../ui/TransparentCard'
import UpdateJobTypes from '../../modals/UpdateJobTypes'
import { useGetContractorDetailsQuery } from '../../../apollo/generated'
import { SET_CONTRACTOR_PHOTOS } from '../../../apollo/mutations'
import UpdateBasicInfo from './UpdateBasicInfo'
import { formatTradeGroup } from '../../../utils/helpers'
import ResponsiveModal from '../../ui/ResponsiveModal'
import UploadOverlay from '../../ui/UploadOverlay'
import ConfigurableImage from '../../ui/ConfigurableImage'
import IconCard from '../../ui/IconCard'
import UpdateCounties from './UpdateCounties'
import Loading from '../../ui/Loading';

const ContractorProfile = () => {
	const [modal, setModal] = React.useState<undefined | 'trades' | 'photos' | 'counties'>(undefined)

	const { data: contractorData, loading: loadingContractor, refetch } = useGetContractorDetailsQuery();
	const [setPhotosMut] = useMutation(SET_CONTRACTOR_PHOTOS)
	const setPhotos = async (urlList: string[]) => {
		await setPhotosMut({ variables: { urlList } })
		refetch()
	}

	const renderModalRow = () => {
		if (loadingContractor) {
			return (
				<ModalRow>
					<IconCard>
						<Loading dark relative />
					</IconCard>
				</ModalRow>
			);
		}
		const onCloseModal = () => setModal(undefined);

		const { contractor: { counties, workPictureUrls } } = contractorData;
		return (
			<ModalRow>
				<Inner>
					<Header>Pictures of Our Work</Header>
					<WorkPics>
						{(workPictureUrls || []).map((url, ii) => (
							<ConfigurableImage
								imageStyle={{ border: '1px solid grey', borderRadius: 4, objectFit: 'cover' }}
								src={url}
								onDelete={() => setPhotos(workPictureUrls.filter((_, rem_ind) => ii !== rem_ind))}
								lightboxOnClick
								key={ii}
							/>
						))}
						<AddWorkPic>
							<UploadOverlay maxNumAllowed={5} onUpload={urls => setPhotos([...(workPictureUrls || []), ...urls])}>
								<AddIcon />
							</UploadOverlay>
						</AddWorkPic>
					</WorkPics>
				</Inner>
				<Inner>
					<Header>Trades We Perform</Header>
					<TransparentCard onClick={() => setModal('trades')}>
						{contractorData.contractor.trades.map(t => <Chip key={t.id} color="secondary" style={{ margin: 2 }} label={formatTradeGroup(t.group)} />)}
					</TransparentCard>
					{modal === 'trades' && <ResponsiveModal dialogProps={{ maxWidth: 'sm' }} open onClose={onCloseModal}><UpdateJobTypes /></ResponsiveModal>}
				</Inner>
				<Inner>
					<Header>Counties We Serve</Header>
					<SelectedCounties onClick={() => setModal('counties')}>
						{counties.map(c => <Chip key={c.id} color="secondary" label={`${c.name}, ${c.state.name}`} style={{ marginRight: "6px", marginTop: "4px" }} />)}
					</SelectedCounties>
					{modal === 'counties' && (
						<ResponsiveModal open onClose={onCloseModal}>
							<UpdateCounties
								key={loadingContractor ? "countySelectLoading" : "countySelect"}
								initialCounties={counties ? counties.map(c => c.id) : undefined}
								sortCounties
								onComplete={onCloseModal}
							/>
						</ResponsiveModal>
					)}
				</Inner>
			</ModalRow>
		);
	};

	return (
		<Outer>
			<Header>Company Profile</Header>
			<UpdateBasicInfo />
			{renderModalRow()}
		</Outer>
	)
}

export default React.memo(ContractorProfile)

const Header = styled(Typography).attrs({
	variant: 'h6',
})`
	margin: 8px;
	color: white;
`

const ModalRow = styled.div`
	margin: 24px;
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	width: 100%;
	> * {
		flex: 1;
		display: flex;
		flex-direction: column;
		> div {
			flex: 1;
			flex-wrap: wrap;
		}
	}
`

const Outer = styled.div`
	display: flex;
	flex-wrap: wrap;
	flex: 1;
	padding: 24px;
	overflow-y: auto;
	max-height: 100vh;
`

const Inner = styled.div`
	margin: 16px;
`

const WorkPics = styled(TransparentCard)`
	align-items: center;
	justify-content: space-evenly;
	overflow: visible;
	display: flex;
	flex-wrap: wrap;
	> * {
		margin: 4px;
	}
`

const AddWorkPic = styled.div`
	background-color: rgba(0,0,0,.3);
	width: 84px;
	height: 84px;
	border-radius: 4px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 24px;
	&:hover {
		background-color: rgba(0,0,0,.2);
	}
	> *:first-child > *:nth-child(2) {
		opacity: 0;
	}
`

const SelectedCounties = styled(TransparentCard)`
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	align-content: space-around;
`;
