import React from 'react'
import styled from 'styled-components'
import Select from 'react-select'

import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import ErrorIcon from '@material-ui/icons/Error'

import IconCard from '../../ui/IconCard'
import GenericSnackbar, { useSnackbar } from '../../ui/GenericSnackbar'
import WaitButton, { useWaitButton } from '../../ui/WaitButton'

import { useGetCounties } from '../../../apollo/queries'
import { useSetContractorCounties } from '../../../apollo/mutations'
import { County, State } from '../../../apollo/types'
import { Chip } from '@material-ui/core';
import Swal from 'sweetalert2';

interface UpdateCountiesProps {
	initialCounties?: string[]
	onComplete?: () => void
	sortCounties?: boolean
}

interface OptionType { label: string, value: string, group?: string }
interface GroupType {
	options: ReadonlyArray<OptionType>;
	[key: string]: any;
}

const createOption = ({ id, name, state }: County): OptionType => ({
	label: `${name}, ${state.name}`,
	value: id,
	group: state.name
});
const createGroup = ({ id, name, counties }: State): GroupType => ({
	options: (counties || []).map(c => createOption({ state: { id, name }, ...c })),
	label: name,
});
function compareString(l: string, r: string): number {
	const a = l.toUpperCase(), b = r.toUpperCase();

	return a < b ? -1 : (a > b ? 1 : 0);
}

function mapFlatten<TIn, TOut>(items: TIn[], map: (item: TIn) => ReadonlyArray<TOut>): ReadonlyArray<TOut> {
	return (items || []).map(map).reduce((arr, itm) => arr.concat(itm), []);
}
const UpdateCounties = ({ initialCounties, onComplete, sortCounties }: UpdateCountiesProps) => {
	const [counties, setCounties] = React.useState(initialCounties || []);
	const { data: { states }, loading, error } = useGetCounties();
	const [setContractorCounties] = useSetContractorCounties({ variables: { counties } });
	const snackbar = useSnackbar("error");

	const onSave = async () => {
		try {
			await setContractorCounties();
			Swal.fire({
				title: 'Success',
				text: 'Saved Counties Successfully!',
				type: 'success'
			})

			if (onComplete) { onComplete(); }
		} catch (err) {
			snackbar.showError(`Failed to save counties: ${err}`, err);
		}
	};

	const waitButton = useWaitButton(onSave, { disabled: loading || !!error });

	const groups = (states || []).map(createGroup);
	const options = mapFlatten(groups, g => g.options);

	const allIds = mapFlatten(states, s => s.counties.map(c => c.id));
	let values = counties.filter(id => allIds.includes(id)).map(id => options.find(opt => opt.value === id));
	if (sortCounties) {
		values = values.sort((l, r) => l.group === r.group ? compareString(l.label, r.label) : compareString(l.group, r.group));
	}

	const onChange = (selected: any) => {
		if (!counties.includes(selected.value)) {
			setCounties(counties.concat([selected.value]));
		}
	};
	const onDelete = (opt: OptionType) => {
		return () => setCounties(values.map(v => v.value).filter(id => id !== opt.value));
	}

	return (
		<Container>
			{error && (
				<IconCard icon={<ErrorIcon color="secondary" style={{ width: 84, height: 84 }} />}>
					<Typography variant="body2">Error: {error.message}</Typography>
				</IconCard>
			)}
			{!error && (
				<>
					<FormControl component="fieldset">
						<FormLabel component="legend" style={{ marginBottom: "8px" }}>Counties You Serve</FormLabel>
						<SelectedCounties>
							{values.map(opt => (
								<Chip
									color="secondary"
									style={{ marginBottom: "8px", marginRight: "6px" }}
									key={opt.value}
									label={opt.label}
									onDelete={onDelete(opt)}
								/>
							))}
						</SelectedCounties>
						<FormGroup>
							<Select
								isDisabled={waitButton.disabled}
								isLoading={loading}
								options={groups}
								onChange={onChange}
								value={null}
								menuPortalTarget={document.body}
								isOptionDisabled={(opt) => counties.includes(opt.value)}
								placeholder="Add a county..."
								styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
							/>
						</FormGroup>
					</FormControl>
					<div style={{ alignSelf: "flex-end", marginTop: "8px" }}>
						<WaitButton {...waitButton}>Save</WaitButton>
					</div>
				</>
			)}
			<GenericSnackbar {...snackbar} />
		</Container>
	);
}

export default UpdateCounties

const Container = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 95vw;
	min-width: 150px;
	font-family: "Open Sans", "Helvetica", "Arial", sans-serif;
`;

const SelectedCounties = styled.div`
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
`;