import React from 'react'
import styled from 'styled-components'
import { Modal } from '@material-ui/core'
import XIcon from '@material-ui/icons/CloseOutlined'

interface Props {
  onDelete?: () => void
  lightboxOnClick?: boolean
  style?: React.CSSProperties
  imageStyle?: React.CSSProperties
  src?: string
}

const ConfigurableImage = ({ onDelete, lightboxOnClick, style, imageStyle, src }: Props) => {
  const [showlightbox, setShowLightbox] = React.useState(false)

  return (
    <Outer style={style} >
      <Image src={src} style={imageStyle} onClick={() => lightboxOnClick && setShowLightbox(true)} />
      {onDelete && <XButton onClick={onDelete} />}
      <Modal
        open={showlightbox}
        onClose={() => setShowLightbox(null)}
      >
        <LightboxPhoto src={src} />
      </Modal>
    </Outer>
  )
}

export default React.memo(ConfigurableImage)

const Image = styled.img`
  width: 100%;
  height: 100%;
`

const XButton = styled(XIcon)`
  font-size: 34px;
  color: red;
  position: absolute;
  right: 0px;
  top: 0px;
  transform: translate(50%, -50%);
  opacity: 0;
  transition: opacity .3s ease-out;
  cursor: pointer;
  padding: 2px;
  background-color: white;
  border-radius: 48px;
  &&&:hover {
    opacity: 1;
  }
`

const Outer = styled.div`
  cursor: pointer;
  width: 84px;
  height: 84px;
  position: relative;

  &:hover ${XButton} {
    opacity: 1;
  }
`

const LightboxPhoto = styled.img`
	position: absolute;
	max-width: 90vw;
	max-height: 90vh;
	margin: auto;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	border: 1px solid rgba(255, 255, 255, 0.4);
`
