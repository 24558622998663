import React from 'react'
import styled from 'styled-components'
import { CircularProgress, Typography } from '@material-ui/core'

interface Props {
	dark?: boolean
	relative?: boolean
	style?: any
	noText?: boolean
}

const Loading = ({dark, relative, style, noText}: Props) => (
	<Outer style={{position: relative ? 'relative' : 'absolute', ...(style || {})}}>
		<CircularProgress color="primary" />
		{!noText && <LoadingText style={{color: dark ? '#333' : 'white'}}>LOADING</LoadingText>}
	</Outer>
)

export default React.memo(Loading)

const Outer = styled.div`
	pointer-events: none;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 2000;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`

const LoadingText = styled(Typography)`
	margin-top: 8px;
	color: white;
	font-weight: 500;
	text-align: center;
	font-size: 18px;
`
